@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('../public/Roboto-Regular.ttf') format('truetype');
}


#root {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

}

html,
body {
  position: relative;
  height: 100%;
  overflow: hidden;

}


.App {
  height: 100%;
}

body {
  background: #ffffff;
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  height: 100vh !important;
  width: 100vw !important;
}

/* Table Container */
.table-container {
  height: 100%;
  width: 100%;
  max-width: 1170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
}

.swiper-slide {
  transform: scale(1);
  transform-origin: top top;
}

.criteria-container {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


.swiper-container {
  width: 75%;
}

.swiper {
  width: 99.9%;
  height: 100vh;
}

/* .swiper-slide {
  width: 280px !important;
} */

.swiper-container {
  overflow: visible;

}

.swiper-horizontal {
  touch-action: pan-y;
  overflow: visible;
  margin-left: 0px !important;
}

.swiper,
.swiper-container {
  overflow-x: clip !important;
  overflow-y: visible !important;
}


.criteria-container {
  z-index: 2;
}



.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  position: relative;
  overflow-x: clip;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Product Card Container Elements Start */

.product-card {

  margin: 0px 3px;
}

.product-card-header {
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ccc;
  background-color: #F5F8FF;
  z-index: 4;
  /* margin: 0px 5px 0px 5px; */
  border-width: 1px 1px 1px 1px;
  padding: 15px 15px 15px 15px;
  display: flex;
  top: 0;
  position: sticky !important;
  flex-direction: column;
  align-items: center;
}

.product-title {
  font-size: 18px;
  font-weight: 600;
}

.product-image-container {
  width: 50%;
}

object {
  width: 100%;
  height: 100%;
}

.product-grade-container {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 60px;

}

.product-grade-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.basics-criteria {
  margin-bottom: 75px;
}

.basics-criteria .criteria-design {
  padding: 15px;
  border: 1px solid #ccc;
  border-width: 0px 1px 1px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.basics-criteria .criteria-one {
  border: 1px solid #ccc;
  border-width: 1px 1px 1px 1px;
  border-radius: 8px 8px 0px 0px;
}

.criteria-one {
  border-radius: 8px 8px 0px 0px;
}

.product-card-header.criteria {
  visibility: hidden;
}

.arrowLeft {
  position: absolute;
  left: 0;
}

.arrowRight {
  position: absolute;
  right: 0;
}


.arrow.left {
  left: 0;
  transform: translate3d(13px, -50%, 0) rotate(-135deg);
}

.arrow {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top: 3px solid #009fe3;
  border-right: 3px solid #009fe3;
  box-shadow: 0 0 0 lightgray;
  transition: all 200ms ease;
}

.arrow.right {
  right: 0;
  transform: translate3d(-13px, -50%, 0) rotate(45deg);
}

.arrow:hover {
  border-color: rgb(0, 81, 255);
  box-shadow: 0.5vmin -0.5vmin 0 rgba(255, 255, 255, 0.099);
}

.arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -60%) rotate(45deg);
  width: 200%;
  height: 200%;
}

.basics .criteria-seven,
.server .criteria-six,
.communication .criteria-three,
.mobile-working .criteria-four,
.automation .criteria-three,
.user-interface .criteria-three,
.specialized-dms .criteria-five,
.support .criteria-two,
.focus .criteria-three,
.updates .criteria-four,
.cost-variants .criteria-six,
.ratings .criteria-two,
.criteria-seven {
  border-radius: 0px 0px 8px 8px !important;
}

.product-grade-container.criteria {
  padding: 15px 15px 15px 15px;
}

.criteria-design.origin {
  font-weight: 600;
}

.criteria-container .basics-criteria::before {
  content: "Basics";
  font-weight: 500;
  font-size: 18px;
  position: relative;
  top: -3px;
  left: 0px;
  color: white;
  background-color: #009fe3;
  padding: 3px 15px 3px 15px;
  border-radius: 0px;
}

.criteria-container .server.basics-criteria::before {
  content: "Server";
}

.criteria-container .communication.basics-criteria::before {
  content: "Kommunikation";
}

.criteria-container .mobile-working.basics-criteria::before {
  content: "mobiles Arbeiten";
}

.criteria-container .automation.basics-criteria::before {
  content: "Automation";
}

.criteria-container .user-interface.basics-criteria::before {
  content: "User Interface";
}

.criteria-container .specialized-dms.basics-criteria::before {
  content: "Eigenes spezialisiertes  DMS";
}

.criteria-container .focus.basics-criteria::before {
  content: "Schwerpunkt";
}

.criteria-container .updates.basics-criteria::before {
  content: "Updates";
}

.criteria-container .cost-variants.basics-criteria::before {
  content: "Kosten / Varianten";
}

.criteria-container .ratings.basics-criteria::before {
  content: "Ratings";
}

.criteria-container .support.basics-criteria::before {
  content: "Support";
}

.criteria-container .basics-criteria {
  margin-top: -21.55px;
}

.swiper-container .basics-criteria {
  margin-bottom: 75.4px;
}

/* .criteria-container .server,
.criteria-container .automation,
.criteria-container .mobile-working,
.criteria-container .user-interface,
.criteria-container .support,
.criteria-container .focus,
.criteria-container .updates,
.criteria-container .cost-variants,
.criteria-container .ratings,
.criteria-container .specialized-dms {
  margin-top: 69.9px !important;
}

.criteria-container .communication {
  margin-top: 69.9px !important;
}

.swiper-container .communication {
  margin-top: 90px !important;
}

.swiper-container .server {
  margin-top: 89px !important;
}

.swiper-container .automation {
  margin-top: 91px !important;
}


.swiper-container .mobile-working {
  margin-top: 91px !important;
}


.swiper-container .user-interface {
  margin-top: 90px !important;
}

.swiper-container .specialized-dms {
  margin-top: 90.5px !important;
}

.swiper-container .support {
  margin-top: 90.5px !important;
}

.swiper-container .focus {
  margin-top: 90.5px !important;
}


.swiper-container .updates {
  margin-top: 90.5px !important;
}

.swiper-container .cost-variants {
  margin-top: 90px !important;
}


.swiper-container .ratings {
  margin-top: 90px !important;
} */

.backBtn {
  position: absolute;
  left: 12px;
  transform: rotate(180deg) scale(2.2);
  z-index: 2;
  top: 100px;
  border-radius: 12px;
  cursor: pointer;
  display: none;
  user-select: none;
}

.swiper-button-next,
.swiper-button-prev {
  position: relative !important;
  pointer-events: none;
  opacity: 0 !important;
  z-index: 4;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 0.099)) !important;


}

.btn-container-new {
  width: 100%;
  top: 56px;
  z-index: 3;
  justify-content: space-between;
  height: max-content;
  display: flex;
  position: absolute;
}

.swiper-container {
  position: relative;
  --swiper-container-top: 0px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 35px !important;
  z-index: 3 !important;
}


.pin {

  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 3;
  width: 15px;

}

.pin span {
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgb(13, 13, 13);
  border-radius: 12px;
  padding: 0px 2px 0px 3px;
  cursor: pointer;
}



.pinnedProductContainer {
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

}

.pinnedProductContainer .swiper-slide {
  width: 283px !important;
  z-index: 2;
}

.criteria-filter-container {
  top: 0;
}

/*  */

.product-card-header {
  position: absolute !important;
  opacity: 0;
  pointer-events: none;
}

.btn-container-new {
  opacity: 0;
  pointer-events: none;
}


.filter-container {
  display: flex;
  position: absolute;
    opacity: 0;
    pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
  padding: 10px 10px 30px 10px;

}


.filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 900px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

.filter-item input {
  margin-right: 8px;
}

.filter-item label {
  margin: 0;
}

.mySwiper .swiper-wrapper .swiper-slide:first-child {
  margin-left: 0.4px;
}

.software-link a {
  text-decoration: none;
  color: #007aff
}

.arrow-link {
  color: #007aff
}

@media (max-width: 1170px) {



  .btn-container-new {
    top: 65px;
  }

  .swiper {
    width: 100%;
    height: 100vh;
  }


  .criteria-container {
    width: 50%;
  }

  .swiper-container {
    width: 50%;
  }

  .table-container {
    width: 60%;
  }

  /* .criteria-container .basics-criteria {
    margin-top: -23px;
  } */

  .product-grade-container.criteria {
    margin-top: 20x;
    margin-bottom: 60px;
  }

  .criteria-container .specialized-dms.basics-criteria::before {
    content: "Eigenes spez. DMS";
  }

}

@media (max-width: 900px) {

  .filter {
    max-width: 700px;
  }


}

@media (max-width: 734px) {

  .swiper-container .basics-criteria {
    margin-bottom: 75px;
  }

  .btn-container-new {
    top: 45%;
  }

  .swiper {
    width: 100%;

  }

  .criteria-design {
    margin-bottom: 0px;
  }

  .basics-criteria .criteria-design {
    border: 1px solid #ccc;
    border-width: 1px 1px 1px 1px;
  }

  /* dddfdfd d.basics.basics-criteria.values.mobil-heading:before,
  .specialized-dms.basics-criteria.values.mobil-heading:before,
  .cost-variants.basics-criteria.values.mobil-heading:before,
  .server.basics-criteria.values.mobil-heading:before,
  .automation.basics-criteria.values.mobil-heading:before,
  .communication.basics-criteria.values.mobil-heading:before,
  .mobile-working.basics-criteria.values.mobil-heading:before,
  .user-interface.basics-criteria.values.mobil-heading:before,
  .support.basics-criteria.values.mobil-heading:before,
  .focus.basics-criteria.values.mobil-heading:before,
  .updates.basics-criteria.values.mobil-heading:before,
  .ratings.basics-criteria.values.mobil-heading:before,
  .specialized-dms.basics-criteria.values.mobil-heading:before,
  .ratings.basics-criteria.values.mobil-heading:before
  
  {

    position: absolute;
    background: rgb(0, 81, 255);
    padding: 5px 0px;
    color: white;
    left: 0;
    width: 80%;
    left: 10%;

}

.basics.basics-criteria.values.mobil-heading:before {
  content: "Basics";
  transform: translate(0px, -30px);
}

.specialized-dms.basics-criteria.values.mobil-heading:before {
  content: "Specialized DMS";
  transform: translate(0px, -30px);

}

.cost-variants.basics-criteria.values.mobil-heading:before {
  content: "Kosten/Varianten";
  transform: translate(0px, -30px);
}

.server.basics-criteria.values.mobil-heading:before {
  content: "Server/Zugriff";
  transform: translate(0px, -30px);
}

.updates.basics-criteria.values.mobil-heading:before {
  content: "Updates";
  transform: translate(0px, -30px);
}

.ratings.basics-criteria.values.mobil-heading:before {
  content: "Bewertungen";
  transform: translate(0px, -30px);
}

.focus.basics-criteria.values.mobil-heading:before {
  content: "Fokus";
  transform: translate(0px, -30px);
}

.support.basics-criteria.values.mobil-heading:before {
  content: "Support";
  transform: translate(0px, -30px);
}

.user-interface.basics-criteria.values.mobil-heading:before {
  content: "Benutzeroberfläche";
  transform: translate(0px, -30px);
}
.mobile-working.basics-criteria.values.mobil-heading:before {
  content: "Mobiles Arbeiten";
  transform: translate(0px, -30px);
}

.automation.basics-criteria.values.mobil-heading:before {
  content: "Automatisierung";
  transform: translate(0px, -30px);
}

.communication.basics-criteria.values.mobil-heading:before {
  content: "Kommunikation";
  transform: translate(0px, -30px);
}

.specialized-dms.basics-criteria.values.mobil-heading,
.swiper-container .ratings, .swiper-container .specialized-dms {
  margin-top: 130px !important;
}

.basics.basics-criteria.values.mobil-heading .criteria-one:before,
.basics.basics-criteria.values.mobil-heading .criteria-two:before,
.basics.basics-criteria.values.mobil-heading .criteria-three:before,
.basics.basics-criteria.values.mobil-heading .criteria-four:before,
.basics.basics-criteria.values.mobil-heading .criteria-five:before,
.basics.basics-criteria.values.mobil-heading .criteria-six:before,
.basics.basics-criteria.values.mobil-heading .criteria-seven:before,
.basics.basics-criteria.values.mobil-heading .criteria-eight:before,
.specialized-dms.mobil-heading .criteria-one:before,
.specialized-dms.mobil-heading .criteria-two:before,
.specialized-dms.mobil-heading .criteria-three:before,
.specialized-dms.mobil-heading .criteria-four:before,
.specialized-dms.mobil-heading .criteria-five:before,
.specialized-dms.mobil-heading .criteria-six:before,
.cost-variants.mobil-heading .criteria-one:before,
.cost-variants.mobil-heading .criteria-two:before,
.cost-variants.mobil-heading .criteria-three:before,
.cost-variants.mobil-heading .criteria-four:before,
.cost-variants.mobil-heading .criteria-five:before,
.cost-variants.mobil-heading .criteria-six:before,
.server.mobil-heading .criteria-one:before,
.server.mobil-heading .criteria-two:before,
.server.mobil-heading .criteria-three:before,
.server.mobil-heading .criteria-four:before,
.server.mobil-heading .criteria-five:before,
.server.mobil-heading .criteria-six:before, 
.updates.mobil-heading .criteria-one:before,
.updates.mobil-heading .criteria-two:before,
.updates.mobil-heading .criteria-three:before,
.updates.mobil-heading .criteria-four:before,
.ratings.mobil-heading .criteria-one:before,
.ratings.mobil-heading .criteria-two:before,
.ratings.mobil-heading .criteria-three:before,
.focus.mobil-heading .criteria-one:before,
.focus.mobil-heading .criteria-two:before,
.focus.mobil-heading .criteria-three:before,
.support.mobil-heading .criteria-one:before,
.support.mobil-heading .criteria-two:before,
.user-interface.values.mobil-heading .criteria-one:before, 
.user-interface.values.mobil-heading .criteria-two:before,
.user-interface.values.mobil-heading .criteria-three:before,
.mobile-working.values.mobil-heading .criteria-one:before,
.mobile-working.values.mobil-heading .criteria-two:before,
.mobile-working.values.mobil-heading .criteria-three:before,
.mobile-working.values.mobil-heading .criteria-four:before,
.automation.values.mobil-heading .criteria-one:before,
.automation.values.mobil-heading .criteria-two:before,
.automation.values.mobil-heading .criteria-three:before,
.communication.values.mobil-heading .criteria-one:before,
.communication.values.mobil-heading .criteria-two:before,
.communication.values.mobil-heading .criteria-three:before

{
  position: absolute;
  border-radius: 30px;
  transform: translate(0px, 30px);
  background: rgb(0, 81, 255);
  padding: 5px 5px;
  width: 90%;
  color: white;
  font-size: 11px;
}

.cost-variants.values.mobil-heading .criteria-one:before {
  content: "Preis-Indikator";
}

.cost-variants.values.mobil-heading .criteria-two:before {
  content: "Software-Kauf";
}

.cost-variants.values.mobil-heading .criteria-three:before {
  content: "Software-Miete monatlich";
}

.cost-variants.values.mobil-heading .criteria-four:before {
  content: "Software-Miete jährlich";
}

.cost-variants.values.mobil-heading .criteria-five:before {
  content: "Gleichzeitige Nutzer";
}

.cost-variants.values.mobil-heading .criteria-six:before {
  content: "Benannte Nutzer";
}

.server.values.mobil-heading .criteria-one:before {
  content: "Kanzleiserver (On-Premise)";
}

.server.values.mobil-heading .criteria-two:before {
  content: "Eigener 'Cloud'-Server (Terminal-Server)";
}

.server.values.mobil-heading .criteria-three:before {
  content: "Cloud als Saas";
}

.server.values.mobil-heading .criteria-four:before {
  content: "Browserzugriff auf Saas";
}

.server.values.mobil-heading .criteria-five:before {
  content: "App-Zugriff auf Saas";
}

.server.values.mobil-heading .criteria-six:before {
  content: "Saas inkl. Software, E-Mail, Office 365 und Videochat";
}

.updates.values.mobil-heading .criteria-one:before {
  content: "Durchschn. Wartezeit";
}

.updates.values.mobil-heading .criteria-two:before {
  content: "Durchschn. Update-Intervall";
}

.updates.values.mobil-heading .criteria-three:before {
  content: "Datenimport aus bestehnder Software";
}

.updates.values.mobil-heading .criteria-four:before {
  content: "Automatische Updateinstallation";
}

.ratings.values.mobil-heading .criteria-one:before {
  content: "Google-Bewertungen";
}

.ratings.values.mobil-heading .criteria-two:before {
  content: "Kununu-Bewertungen";
}

.focus.values.mobil-heading .criteria-one:before {
  content: "Kanzleigröße";
}

.focus.values.mobil-heading .criteria-two:before {
  content: "Begerenzing auf bestimme Rechtsgebiete";
}

.focus.values.mobil-heading .criteria-three:before {
  content: "Notar-Funktion";
}

.support.values.mobil-heading .criteria-one:before {
  content: "Telefonischer Support";
}

.support.values.mobil-heading .criteria-two:before {
  content: "Durchschn. Wartezeit Hotline";
}

.user-interface.values.mobil-heading .criteria-one:before {
  content: "Bedienbarkeit";
}

.user-interface.values.mobil-heading .criteria-two:before {
  content: "Praktische Struktur";
}

.user-interface.values.mobil-heading .criteria-three:before {
  content: "Mehrere Akten parallel öffnen";
}

.mobile-working.values.mobil-heading .criteria-one:before {
  content: "Offline Zugriff";
}

.mobile-working.values.mobil-heading .criteria-two:before {
  content: "Synchronisierung offline vorgenommer Änderungen";
}

.mobile-working.values.mobil-heading .criteria-three:before {
  content: "Cloud-Zugriff via Browser und App";
}

.mobile-working.values.mobil-heading .criteria-four:before {
  content: "Termin- und Aufgabenverwaltung auf dem Smartphone";
}


.basics.basics-criteria.values.mobil-heading .criteria-one:before {
  content: "Akten-, Mandanten- u. Beteiligtenverawltung";
}

.basics.basics-criteria.values.mobil-heading .criteria-two:before {
  content: "Gerichtsdatenbank";

}

.basics.basics-criteria.values.mobil-heading .criteria-three:before {
  content: "Terminkalender";

}

.basics.basics-criteria.values.mobil-heading .criteria-four:before {
  content: "Aufgabenverwaltung";

}

.basics.basics-criteria.values.mobil-heading .criteria-five:before {
  content: "RVG-Rechnungsstellung";

}

.basics.basics-criteria.values.mobil-heading .criteria-six:before {
  content: "Zeit-basierte Rechnungsstellung";

}

.basics.basics-criteria.values.mobil-heading .criteria-seven:before {
  content: "Texterstellung auf Basis von Daten aus Akten";

}

.specialized-dms.mobil-heading .criteria-one:before {
  content: "Direkte Verknüpfung mit Akten";
}

.specialized-dms.mobil-heading .criteria-two:before {
  content: "Cloud-Verfügbarkeit";
}

.specialized-dms.mobil-heading .criteria-three:before {
  content: "Automatische Zuordnung von beA- und E-Mail-Anhängen zu Akten";
}

.specialized-dms.mobil-heading .criteria-four:before {
  content: "Aufgabenabläufe mit Dokumentenbezug";
}

.specialized-dms.mobil-heading .criteria-five:before {
  content: "Kollaboratives Bearbeiten";
}

.automation.mobil-heading .criteria-one:before {
  content: "Kanzleiportal für Mandanten";
}

.automation.mobil-heading .criteria-two:before {
  content: "Tool zur Mandantenakquise";
}

.automation.mobil-heading .criteria-three:before {
  content: "beA-Integration";
}

.communication.mobil-heading .criteria-one:before {
  content: "E-Mail-Integration";
}

.communication.mobil-heading .criteria-two:before {
  content: "WebFile";
}

.communication.mobil-heading .criteria-three:before {
  content: "Automatische Zuordnung von beA- und E-Mail-Anhängen";
} */



  .filter {
    padding: 0px 80px;

  }

  .criteria-container {
    /* display: none; */
  }

  .table-container {
    width: 95vw;
  }

  .criteria-container .specialized-dms.basics-criteria::before {
    content: "Eigenes spez. DMS";
  }

  @media (max-width: 600px) {

    /* .swiper-container .server {
      margin-top: 90px !important;
    }

    .swiper-container .communication {
      margin-top: 91.5px !important;
    }

    .swiper-container .automation {
      margin-top: 91.5px !important;
    }

    .swiper-container .mobile-working {
      margin-top: 92px !important;
    }

    .swiper-container .user-interface {
      margin-top: 91px !important;
    }

    .swiper-container .specialized-dms {
      margin-top: 91.5px !important;
    }

    .swiper-container .support {
      margin-top: 91.5px !important;
    }

    .swiper-container .focus {
      margin-top: 92px !important;
    }

    .swiper-container .updates {
      margin-top: 91.5px !important;
    }

    .swiper-container .cost-variants {
      margin-top: 91px !important;
  }
   */
  
  }

  @media (max-width: 570px) and (min-width: 550px) {
    .table-container {
      width: 500px;
    }
  }


  @media (max-width: 500px) {

    .product-grade-container {
      margin-top: 0px;
    }

    .criteria-container .basics-criteria::before {
      top: 0px;
    }

    .criteria-design.origin {
      font-size: 13px;
    }

    .product-grade {
      font-size: 14px;
    }

    .criteria-container .basics-criteria::before {
      font-size: 14px;
    }

    .product-title {
      font-size: 14px;
    }

    .table-container {
      gap: 0px;
    }


  }

  
  @media (max-width: 426px) and (min-width: 410px) {
    .table-container {
      width: 410px;
    }
  }


  @media (max-width: 400px) {

    .btn-container-new {
      top: 45%;
    }

    .filter {
      padding: 0px 31px;
    }

    /* .swiper-container .communication,
    .swiper-container .mobile-working {
      margin-top: 92px !important;
    } */
  }

}